.entity-details-container {
    position: relative;
    top:-80px;
    margin-top:0px;
    padding: 2em;

    .full-time-checkbox {
        margin: 0 2em !important;
    } // Now present as inline style
}

.otp-modal {
    .ant-input-number-handler-wrap {
        display: none;
    }
}

.register {
    .landing-page-header-bar {
        position: unset;


        .header-breadcrumb {
            display: none;
        }
    }

}

.form-item-60 {
    display: inline-block;
    width: 40%;
}


.ant-modal.registration-modal-body1{

    .ant-modal-content{
        max-width:800px;
        width:600px;
            .uploadDoc{
                width: 27%;
                margin: 0 0px;
                // background:green;
                .other-upload{
                    width: 110px;
                    /* background:#12805c; */
                    box-sizing: border-box;
                    border-radius: 0.188rem;
                    color: #12805c;
                    font-weight: 400;
                    font-size: 0.813rem;
                    margin-right: 0px;
                }
            }
            .verification-btn{
                width: 75px;
                background : #12805c;
                border-radius: 10px ;
                color: #ffffff;
                margin-left : 0px;
            }
            
    }
}

.verifybuttons{
    display: flex;
    
}
.ant-upload-list-item-name{
    width: 800px;
}
.pincodeauto{
    display: flex;
}
.pinCode{
    margin-top: -25px;
}
.address{
    margin-top: -50px;
    margin-bottom: 35px;
}
.ant-form-item-explain{
    width: 200px;
}