.crops-container {
    .add-crop-btn {
        width: 280px;
        height: 52px;
        font-weight: 500;
        font-size: 18px;
    }
}

.add-crop-form {
    .additional-text {
        margin: 0 1em;
        white-space: nowrap;
        color: #4e4e4e;
    }

    .crop-modal-action-btn {
        width: 220px;
    }
}

.add-crop-modal .ant-modal-content {
    padding: 2em !important;
}

.crop-images-upload {
    .ant-upload {
        padding: 1em !important;
    }

    & + .ant-upload-list {
        margin-top: 1em !important;
    }
}

.kyc-pending-message {
    display: inline-flex;
    padding-left: 2rem;
    font-size: 1rem;

    .warning-icon {
        color: #EE404C;
    }

    .kyc-pending-text {
        font-weight: 700;
        position: relative;
        top: 0.27rem;
    }

    .update-text {
        text-decoration: underline;
    }
}

.kyc-required-modal {
    .ant-modal-confirm-body-wrapper {
        text-align: center;
        align-content: center;

        .anticon {
            float: none;
        }

        .ant-modal-confirm-btns {
            float: none;
        }
    }
}
