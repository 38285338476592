.crops-container {
    .add-crop-btn {
        width: 280px;
        height: 52px;
        font-weight: 500;
        font-size: 18px;
    }
}

.add-crop-form {
    .additional-text {
        margin: 0 1em;
        white-space: nowrap;
        color: #4E4E4E;
    }

    .crop-modal-action-btn {
        width: 220px;
    }
}

.add-crop-modal .ant-modal-content {
    padding: 2em !important;
}

.crop-images-upload {
    .ant-upload {
        padding: 1em !important;
    }
    
    & + .ant-upload-list {
        margin-top: 1em !important;
    }
}

.kyc-required-modal {
    .ant-modal-confirm-body-wrapper {
        text-align: center;
        align-content: center;

        .anticon {
            float: none;
        }

        .ant-modal-confirm-btns {
            float: none;
        }
    }
}

.additionalInfo-submit{
display: flex;
align-items: center;
justify-content: center;
}

.disabled-row{
    background: rgba(0,0,0,0.1);
    pointer-events:none;
}