.landing-page-header-bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 10rem 2rem 10rem;
    height: 7rem;
    background-color: #fff;
    width: 100%;
    top: 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
    position: fixed;
    z-index: 3;
    overflow: hidden;
    margin-bottom: 100px; 
}

div.landing-page-header-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 90vw;
    margin-top: 0.4rem;
    height: 6.5rem;
}

@media screen and (max-width: 991px) 
{
    span.ant-badge.custom-badge{
        margin-right: 0.7rem;
    }
    
    button.ant-btn.ant-btn-circle.ant-btn-default.ant-btn-lg.undefined.custom-default-button{
        margin-left: 0.7rem;
    }
}

.landing-page-header-wrapper .logo {
    width: 6.5rem;
    height: 5rem;
}

.landing-page-header-wrapper .header-breadcrumb {
    width: 37.5rem;
    font-size: 1rem;
    padding-top: 1.5rem;
}

.landing-page-header-wrapper .custom-login-button {
    position: relative;
    top:10px;
    color: #fff;
    border-radius: 50px;
    width: fit-content;
    height: fit-content;
    background-color:#12805c;
}

.landing-page-header-wrapper .custom-register-button {
     position: relative;
     top: 10px;
     width: 6.375rem;
     height: 2.5rem;
     border-radius: 1.25rem;
}

.landing-page-header-wrapper .action-buttons {
    float: right;
}

.profile-name-id{
    margin-left: 1rem; 
    margin-right: 1rem; 
    width: auto;
}

h4.ant-typography.margin-unset{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

@media screen and (max-width: 991px){
    .profile-name-id{
        width: 45%
    }
}

@media screen and (max-width: 991px) {
    .profile-avatar-button {
        display: none;
    }

    img.ant-image-img.logo {
        position: relative;
        left: -1.5rem;
    }

    .landing-page-header-wrapper{
        justify-content: space-around;
    }
}

@media screen and (max-width: 991px){
    .profile-name-id{
        display: none;
    }

    .landing-page-header-bar{
        height: 6.5rem;
    }

    div.landing-page-header-wrapper{
        margin-top: 1.5rem;
    }
}

@media screen and (max-width: 991px){
    div.ant-space-compact.ant-space-compact-block.ant-dropdown-button.custom-dropdown-button.contact-info{
        display: none;
    }
}

.register-popup-container {
    .custom-register-modal {
        float: right;

        .ant-modal-content {
            background-color: #f2f2f2;
        }
    }

    .register-basic-form {
        .ant-form-item {
            margin-bottom: 0.5rem;
        }
    }
}

.profile-avatar {
    position: relative;
    top: -0.3em;
    right: 0.05em;
    border: 0.01em solid black;
}


div.okrjoy-notifications {
    opacity: 1;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    z-index: 5;
    border:1px solid rgba(0, 0, 0, 0.20);
    cursor: pointer;
    div.okrjoy-icon {
        font-size: 10px;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1.5rem;
        height: 1.5rem;
    div.okrjoy-count{
        position: relative;
        top: -150%;
        left: 70%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items:center;
        justify-content: center;
        width:20px;
        height:20px;
        border-radius: 50%;
        background-color: red;
    }
    }
    div.okrjoy-container{
        position: relative;
        left:300px;
        top:20px;
        padding:0.5rem;
        width:20rem;
        height:fit-content;
        background-color:#A8D1D1;
        margin-top: 1rem;
        border: 0.5px solid #F7F5EB;
        border-radius: 1rem;
        box-shadow: 5px 5px rgba(0, 0, 0, 0.16);
        div.okrjoy-header{
            padding:0.5rem;
            height:2rem;
            background-color:#227C70;
            display: flex;
            border-radius:0.5rem;
            align-items: center;
            justify-content:space-between;
            color:white;
            font-size:1rem;
        }
        div.okrjoy-content{
            padding:5px;
            border-radius: 0.5rem;
            height: fit-content;
            margin-top: 5px;
            margin-bottom: 5px;
            background-color:#FAECD6;
            display: flex;
            flex-direction: row;
            align-items: center;
            div.okrjoy-image{
                display: none;
            }
        }
    }
}

.dropDownLang{
    width:82px;
    height:75px;
    border: 1px solid green;
    background-color:white;
    li{
        list-style-type: none;
        margin-left: -40px;
        &:hover{
            background-color: #319171;
            color:white;
            }
        button{
            border:0px none;
            background-color:inherit;
            cursor:pointer;
        }
    }
}

@media screen and (min-width: 1240px)
{
    .profile-margin{
        ol{
            li{
                padding-left: 0.95rem;
                padding-right: 0.95rem;
            }   
        }
    }
}

.profile-margin{
    // margin-left: 0rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    height: 6rem;
    width:90vw;
    ol{
        display:flex;
        width: 100%;
        flex-direction: row;
        align-items:center;
        justify-content: left;
        font-size: 1rem;
    }

    .termsAndConditions{
        font-weight: bold;
    }

    .termsAndConditions :hover{
        border-bottom: 0.25rem solid #12805c ;
    }
    .ant-btn.ant-btn-circle.ant-btn-default.ant-btn-lg.custom-default-button {
        padding: 0px;
    }
}



.notificationBody{
    position: absolute;
    padding:5px;
    top:100px;
    z-index: 5;
    width:20rem;
    height:400px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 5px rgba(0, 0, 0, 0.8);
    overflow-y: auto;
    .notificationItem{
        position: relative;
        width:100%;
        padding:5px;
        height:fit-content;
        border-bottom:1px solid grey;
        box-shadow: 5px rgba(0, 0, 0, 0.16);
        cursor:pointer ;
        &:hover{
            background-color:rgb(239, 234, 234);
        }
        section{
            width:100%;
            font-size: 13px;
            padding:5px;
            height:fit-content;
            // border: 1px solid gray;
        }
    }
}

.backGroundWhite{
    background-color:white;
}
.backGroundBlue{
    background-color:rgba(205, 240, 234,0.50);
}

// Hide the Mobile Drawer Button
@media screen and (min-width: 767px) {
    .mobile-visible {
        display: none;
    }
}

// // Responsive Styling for mobile and tab portrait
@media screen and (max-width: 767px) {
    .landing-page-header-bar {
        width: 100vw;
        height: 25vh;
        .logo {
            position: relative;
            top: 1.5vh;
            left: -50px;
            width: 6rem !important;
            height: 6rem !important;
        }
        .header-breadcrumb {
            display: none;
        }
    }

    nav.ant-breadcrumb.custom-breadcrumb{
        display: none;
    }

    .mobile-header-breadcrumb {
        display: flex;
        flex-direction: column;
        font-size: 1rem;
        line-height: 2rem;
        padding-top: 1rem;
    }

    .mobile-header-breadcrumb:focus {
        background-color: #12805c21;
    }

    .mobile-visible {
        position: relative;
        // top: 2rem;
        left: 0.6rem;
    }

    .display-flex-row.align-center.profile-margin{
        margin-left: 0.7rem;
    }

    .drawer-register-button {
        width: 90%;
        height: 3rem;
        font-size: 1.25rem;
        border-radius: 0.3rem;
    }

    .contact-details {
        color: #319171;
        text-align: right;
    }

    .custom-login-button,
    .custom-register-button {
        display: none;
    }
}
