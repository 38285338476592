.password-rules-text {
    font-weight: 700;
    padding-top: 2%;
}

.password-text-message {
    color: #4cbf42 !important;
}

.password-error-text-message {
    color: #df3312 !important;
}
