$col-shade-green: #12805c;

.seller-ui-app {
    .landing-page-header-bar {
        position: unset;

        .header-breadcrumb {
            display: none;
        }
    }

    .seller-ui-dashboard {
        position: relative;
        top:-6.25rem;
        padding: 2em;

        .seller-banner {
            width: 100%;
        }
    }
    .app-footer-container {
        position: relative;
        top:0px;
    }
}

.custom-view-crop-photos {
    .ant-drawer-header {
        background-color: $col-shade-green;

        .ant-drawer-title {
            color: white;
        }

        .anticon-close {
            color: white;
        }
    }

    .ant-drawer-body {
        padding: 1.25rem;
        overflow-y: scroll;
        overflow-x: unset;
    }
}

.partial-match {
    font-size: smaller;
    background-color: #f5a31a;
    color: white !important;
    border-radius: 0.5em;
    padding: 0 0.3em;
}

.full-match {
    font-size: smaller;
    background-color: $col-shade-green;
    color: white !important;
    border-radius: 0.5em;
    padding: 0 0.3em;
}

.specifications {
    border: 0.1rem solid lightgrey;
    padding: 1rem;
    border-radius: 0.25rem;
}

.transaction-timeline {
    margin-top: 2rem;

    .ant-timeline-item-label {
        text-align: center !important;
    }

    .ant-timeline-item-content {
        width: 300% !important;
    }

    .ant-timeline-item-head {
        font-size: large !important;
    }

    .is-complete {
        .ant-timeline-item-tail {
            border-left: 2px solid #000000 !important;
        }
    }

    .is-pending {
        .ant-timeline-item-tail {
            border-left: 2px dotted #000000 !important;
        }
    }
}

.table-crop-image {
    width: 5rem !important;
    height: 5rem !important;
}
