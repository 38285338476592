$col-shade-green: #12805c;

.col-green {
    color: $col-shade-green !important;
}

.col-orange {
    color: #f5a31a !important;
}

.box-shadow {
    box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2), 0 0.375rem 1.25rem 0 rgba(0, 0, 0, 0.19);
}

#home {
    width: 100%;
    // background-color:rgb(248, 241, 241);
    z-index: 1;
    position: relative;
    top: 4.375rem;
    height: 53rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    scroll-margin: 20vh;

    .home-wrapper {
        width: 75rem;
        display: flex;
        flex-direction: column;
        // justify-content:center;
        align-items: center;
        overflow: hidden;
        padding-right: 4.375rem;
        height: 37.5rem;
        .ant-col-10 {
            width: 25rem;
            position: relative;
            top: 3.125rem;
            .homeTitle {
                position: relative;
                width: 31.25rem;
                font-style: normal;
                font-weight: 600;
                font-size: 3rem;
            }
            .homeTitleKan {
                width: 31.25rem;
                font-weight: 600;
                font-size: 2.5rem;
            }
            h5.ant-typography {
                font-style: normal;
                font-weight: 300;
                font-size: 1.563rem;
            }

            .wimage {
                background-image: URL('../static/assets/wheatCustomised.svg');
                background-repeat: repeat;
                width: 24.5rem;
                height: 1.75rem;
                color: #f5a31a;
                margin-top: 2rem;
            }

            .registerComponent {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                background-color: #f5a31a;
                align-items: center;
                width: 24.813rem;
                height: 4.563rem;
                padding: 0.625rem;
                border-radius: 0.313rem;
                border: none;

                p {
                    font-size: 0.938rem;
                    margin-top: 0.625rem;
                    color: white;
                    font-weight: 700;
                }

                Button {
                    width: 7.5rem;
                    height: 2.75rem;
                    border-radius: 0.313rem;
                    background-color: #ffffff;
                    font-size: 1rem;
                    color: #f5a31a;
                    border: none;
                }
            }

            .googlePlay {
                position: relative;
                left: -0.625rem;
                width: 12.5rem;
                height: 2.769rem;
                margin-top: 3rem;

                Button,
                img {
                    width: 9.375rem;
                    height: 2.769rem;
                    margin-left: 0rem;
                    border: none;
                }
            }

            .headFooter {
                position: relative;
                top: 3.125rem;
                left: 0rem;
                display: flex;
                flex-direction: column;
                align-self: start;
                width: 31.25rem;
                height: fit-content;

                .firstL {
                    display: flex;
                    width: 10.313rem;
                    height: 1.563rem;
                    justify-content: space-between;
                }

                .secondL {
                    width: 31.25rem;
                    height: 4.313rem;
                    background: #f6faf8;
                    border-radius: 0.625rem;
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    flex-direction: row;
                    text-decoration: underline;

                    p {
                        border-left: 0.063rem solid black;
                    }
                }
            }
        }

        .courselCircle {
            position: relative;
            left: 9.25rem;
            z-index: 0;
            animation: up-down 2s ease-in-out infinite alternate-reverse both;

            @-webkit-keyframes up-down {
                0% {
                    transform: translateY(10px);
                }
                100% {
                    transform: translateX(10px);
                }
            }

            @keyframes up-down {
                0% {
                    transform: translateY(10px);
                }
                100% {
                    transform: translateX(10px);
                }
            }
        }
    }
    .pageDivider {
        position: relative;
        top: 2.5rem;
    }
}

.courselUnit {
    width: 30rem;
    height: 30rem;
    border-radius: 50%;
    background-color: gray;
    margin: 0.625rem;
}

.contentStyle {
    background-color: #f5a31a;
    width: 30rem;
    height: 30rem;
    border-radius: 50%;
    text-align: center;
}

#aim {
    width: 100vw;
    position: relative;
    // top:10rem;
    height: 115rem;
    z-index: 1;
    scroll-margin: -3.125rem;
    // font-family: Roboto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    // background-color: yellow;
    .aim-wrapper {
        display: flex;
        width: 62.5rem;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        // background-color:orange;
        .at-present-text {
            font-style: normal;
            font-weight: 300;
            font-size: 1.5rem;
        }
        .aimFirstRow {
            position: relative;
            left: 2.5rem;
            display: flex;
            width: 62.5rem;
            // justify-content: center;
            // align-self: center;
            // background-color: aqua;
            // padding:0rem 12.5rem 0rem 12.5rem;
            margin-bottom: 4.375rem;
            // top: -20.375rem;

            .aim-Whatis {
                position: relative;
                // background-color: #12805c;
                .aim-header {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 2.25rem;
                }
                p {
                    font-size: 1.125rem;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 1.125rem;
                }
            }
            .videoContent {
                border-radius: 0.625rem;
            }
        }
        .why-vikasBandhu {
            margin-top: 2.5rem;
            font-style: normal;
            font-weight: 700;
            font-size: 2.25rem;
        }

        .ExistingMarketPlaces {
            position: relative;
            display: flex;
            flex-direction: column;
            top: 2.875rem;
            width: 75rem;
            height: 16.25rem;
            background-color: rgba(255, 62, 62, 0.05);
            border-radius: 0.313rem;
            .firstBox {
                display: flex;
                width: 100%;
                height: 6.25rem;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                // background:aqua;
                .firstBoxHeading {
                    // width:35rem;
                    // height:2.25rem;
                    font-weight: 600;
                    font-size: 1.5rem;
                    // position: relative;
                    // top:0.875rem;
                    // left:1.375rem;
                }
                .subHead {
                    width: 43.75rem;
                    height: 2.25rem;
                    font-size: 0.938rem;
                    position: relative;
                    left: 1.375rem;
                }
            }

            .secondBox {
                display: flex;
                flex-direction: row;
                align-items: center;
                // background-color: orange;
                // justify-content:space-evenly;
                .ant-col-4 {
                    position: relative;
                    left: 2.5rem;
                }
                .insidePara {
                    .insideRows {
                        display: flex;
                        flex-direction: row;
                        // background-color:aqua;
                        .ant-col-20 {
                            position: relative;
                            left: -70px;
                            p {
                                width: 56.25rem;
                                font-style: normal;
                                font-size: 1rem;
                                // font-weight: 600;
                            }
                        }
                        .points {
                            div {
                                width: 2rem;
                                height: 2rem;
                                border-radius: 50%;
                                text-align: center;
                                text-justify: center;
                                font-size: large;
                                color: #ff3e3e;
                            }
                        }
                    }
                }
            }
        }

        .BuyerSellerRow {
            position: relative;
            top: 6.25rem;
            width: 75rem;
            height: 26.875rem;
            .mdScreen {
                .BuyerSellerAdvantage {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    .inside {
                        height: 26.875rem;
                        background-color: orange;
                        border-radius: 0.313rem;
                        .headText {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            font-weight: 300;
                            // justify-content: flex-start;
                            // background-color: blue;
                            .span-text {
                                position: relative;
                                font-weight: 600;
                                // left:-3.125rem;
                            }
                        }
                        .CImage {
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            img {
                                width: 7rem;
                                height: 7rem;
                            }
                        }
                        .headText {
                            position: relative;
                            // left:10%;
                            width: 36.5rem;
                            height: 4.375rem;
                            margin-bottom: 3.125rem;
                            .span-text {
                                font-size: 1.875rem;
                            }
                            p {
                                position: relative;
                                margin: 0rem;
                            }
                        }
                        .pointsSection {
                            display: flex;
                            flex-direction: column;
                            // background-color:orange;
                            margin-top: 1.875rem;
                            padding: 0.625rem;
                            line-height: 120%;
                            // font-weight: 600;
                            .sellerBuyerPoints {
                                .ant-col-4 {
                                    position: relative;
                                    left: 1.25rem;
                                    div {
                                        width: 2rem;
                                        height: 2rem;
                                        border-radius: 50%;
                                        text-align: center;
                                        text-justify: center;
                                        line-height: 150%;
                                        font-size: large;
                                        color: #f6682b;
                                    }
                                }
                            }
                        }
                    }
                    .sellerBox {
                        width: 36rem;
                        background: rgba(246, 104, 43, 0.1);
                    }
                    .buyerBox {
                        width: 37.438rem;
                        background: rgba(245, 163, 26, 0.05);
                    }
                }
            }
        }

        .manyMore {
            // margin-top: -2.5rem;
            font-size: 1.125rem;
        }

        hr.solid {
            width: 14rem;
        }

        .seventhBox {
            margin-top: 2.5rem;
            width: 36.438rem;
            height: 4.5rem;
            font-style: normal;
            font-weight: 600;
            font-size: 1.5rem;
            line-height: 150%;
            text-align: center;
        }

        .ninethBox {
            position: relative;
            font-style: normal;
            font-weight: 300;
            font-size: 1.5rem;
            top: 3.25rem;
        }

        .tenthBox {
            position: relative;
            top: 4.25rem;
            display: flex;
            justify-content: space-around;
            flex-direction: row;
            width: 62.5rem;
            // background-color: #f5a31a;

            .ant-col-8 {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 8.125rem;
                height: 8.125rem;
                border-radius: 50%;
                background-color: rgba(245, 163, 26, 0.05);
                margin: 0.625rem;
                text-align: center;

                .firstheading {
                    width: 15rem;
                    height: 1.313rem;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 1.125rem;
                }

                p {
                    width: 15rem;
                    height: 1.313rem;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 1.063rem;
                }
            }
        }
        .pageDivider {
            position: relative;
            top: 18rem;
        }
        .line21 {
            position: relative;
            top: 9.375rem;
        }
        .addressText {
            position: relative;
            top: 10.625rem;
            font-weight: 600;
            font-size: 1.5rem;
        }
        .adressAboveIsusse {
            position: relative;
            top: 12.5rem;
            width: 75rem;
            height: 10.5rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: rgba(18, 128, 92, 0.05);
            border-radius: 0.313rem;
            .addressBriefText {
                width: 66.188rem;
                font-style: normal;
                font-size: 1.5rem;
                // background-color:aqua;
                span {
                    font-size: 1.5rem;
                    font-weight: 400;
                    color: #12805c;
                }
            }
        }
    }
}

#ecosystem {
    position: relative;
    top: 70px;
    height: 162.5rem;
    flex: auto;
    width: 100%;
    // z-index: 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    scroll-margin: 50px;
    // background-color: #12805C;

    .ecosystem-wrapper {
        // position: relative;
        width: 75rem;
        height: 156.25rem;
        margin-top: 3.75rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        // background-color:blue;

        .Solution {
            text-align: center;
            justify-items: center;
            width: 75rem;
            height: 12.5rem;
            // background-color:yellow;
            height: 2.625rem;
            font-style: normal;
            font-size: 2.25rem;
            font-style: normal;
            font-weight: 700;
            font-size: 2.25rem;
        }

        .technologyDiffereniator {
            position: relative;
            // background-color:rgb(184, 198, 198);
            width: 75rem;
            height: 62.5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            .headinginfirstsec {
                width: 75rem;
                font-style: normal;
                font-weight: 600;
                font-size: 1.5rem;
                text-align: start;
                // background-color:#4285F4;
            }
            .technologyBoxes {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
                width: 75rem;
                height: 25rem;
                // background-color:orange;
                .onclick {
                    scroll-margin: 100px;
                }
                .firstBox,
                .secondBox,
                .thirdBox,
                .fourthBox {
                    width: 17.5rem;
                    height: 22.125rem;
                    box-sizing: border-box;
                    border-radius: 1.563rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                    &:hover {
                        cursor: pointer;
                    }
                    .inner-text {
                        position: relative;
                        top: -2.5rem;
                        font-weight: 700;
                        width: 12.664rem;
                        height: 1.313rem;
                        font-size: 1.125rem;
                        text-align: center;
                    }
                    ul {
                        width: 14.563rem;
                        height: 11.5rem;
                        // background-color:aqua;
                        font-weight: 400;
                        font-style: normal;
                        font-size: 1.125rem;
                        line-height: 1.313rem;
                    }
                    .topImage {
                        position: relative;
                        top: 2%;
                        left: 27%;
                        -ms-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%);
                        width: 9.875rem;
                        height: 9.875rem;
                        background-color: white;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.25);
                    }
                }
                .firstBox {
                    border: 0.063rem solid #f7682b;
                    background: rgba(246, 104, 43, 0.1);
                }
                .secondBox {
                    border: 0.063rem solid #f5a31a;
                    background: rgba(245, 163, 26, 0.1);
                }
                .thirdBox {
                    border: 0.063rem solid #12805c;
                    background: rgba(18, 128, 92, 0.1);
                }
                .fourthBox {
                    border: 0.063rem solid #4285f4;
                    background: rgba(66, 133, 244, 0.1);
                }
            }
            .OnhoverDisplay {
                position: relative;
                top: -50px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: column;
                width: 75rem;
                height: 12.5rem;
                // background-color:green;
                .OnHoverContentBox {
                    width: 75rem;
                    height: 12.5rem;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                    align-items: center;
                    // background-color:red;
                    .OnHoverImage {
                        width: 12.5rem;
                        height: 12.5rem;
                    }
                    .OnHoverPara {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        text-align: flex-start;
                        width: 50rem;
                        height: 9.875rem;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 1.25rem;
                        line-height: 120%;
                        // background-color:yellow;
                    }
                }
            }
        }
        #howVikasbandhuworks {
            width: 75rem;
            height: 62.5rem;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            scroll-margin: 6.25rem;
            .firstHead {
                text-align: start;
                width: 75rem;
                height: 2.625rem;
                font-style: normal;
                font-weight: 600;
                font-size: 1.5rem;
                // background-color: #12805c;
            }

            .container-Phone {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                width: 75rem;
                height: 31.25rem;
                // background-color:blue;

                .iPhoneContainer {
                    position: relative;
                    top: -3.125rem;
                    padding: 0rem;
                    width: 75rem;
                    height: 43.75rem;
                    // background-color:yellow;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                    div {
                        padding: 0rem;
                        width: 15.625rem;
                        height: 5rem;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-evenly;
                        align-items: center;
                        img {
                            position: relative;
                            top: -0.625rem;
                            height: 4.375rem;
                            // background-color: #4285F4;
                        }
                        p {
                            width: 12.5rem;
                            height: 4.375rem;
                            // background-color:red;
                        }
                    }
                }
            }
            img {
                position: relative;
                top: 6.25rem;
            }
            .keyFeatures {
                font-style: normal;
                font-weight: 600;
                font-size: 1.5rem;
            }
            .lastContent-ecosystem {
                .lastContentWeb {
                    width: 75rem;
                    height: 9.375rem;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                    align-items: center;
                    margin-top: 50px;
                    // background-color: #f6682b;
                    .lastContentBadges {
                        width: 6.25rem;
                        height: 6.25rem;
                        border-radius: 50%;
                        background-color: rgba(245, 163, 26, 0.05);
                        display: flex;
                        flex-direction: column;
                        margin: 0rem 3.125rem 0rem 3.125rem;
                        align-items: center;
                        text-align: center;
                        div {
                            position: relative;
                            top: -7.188rem;
                            width: 2.5rem;
                            height: 2.5rem;
                            margin: 0.125rem;
                            z-index: 1;
                        }
                        .insideText {
                            width: 12.663rem;
                            height: 1.313rem;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 1.125rem;
                            margin: 0rem;
                        }
                        .outerText {
                            width: 12.663rem;
                            height: 2.5rem;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 0.875rem;
                            margin-top: 10px;
                        }
                    }
                }
            }
            .pageDivider {
                position: relative;
                top: 18.75rem;
            }
            .ecosystem-title-kn {
                position: relative;
                width: fit-content;
            }
        }
        #BestPracticesContainer {
            width: 75rem;
            height: 43.75rem;
            // background-color:red;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            scroll-margin: 6.25rem;
            .BestPracticesText {
                font-style: normal;
                font-weight: 600;
                font-size: 1.5rem;
                width: 75rem;
                height: 2.25rem;
                text-align: start;
                // background-color:blue;
            }
            .theInsideBest {
                width: 43.75rem;
                // background-color:yellow;
                ul {
                    list-style-type: circle;
                }
            }
        }
        .inAdditionText {
            font-style: normal;
            font-weight: 300;
            font-size: 1.5rem;
            width: 36.438rem;
            height: 2.25rem;
            text-align: center;
        }
        .inAdditionContent {
            width: 62.5rem;
            height: 12.5rem;
            .inAdditionWeb {
                display: flex;
                padding: 0rem;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                // background-color: #f5a31a;
                .insideContainer {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    // justify-content: space-between;
                    width: 12.664rem;
                    height: 10rem;
                    background-position: center;
                    background-image: URL('../static/assets/blob (1).png');
                    background-repeat: no-repeat;
                    div {
                        position: relative;
                        top: 1.875rem;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        width: 15.063rem;
                        // background-color:blue;
                        height: 5.625rem;
                        .insideText {
                            width: 18.063rem;
                            height: 2.313rem;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 1.125rem;
                            // background-color: #12805c;
                        }
                        p {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 0.875rem;
                            width: 12.664rem;
                            height: 2rem;
                        }
                    }
                }
            }
        }
    }
}

#commodities {
    position: relative;
    top: 50px;
    width: 100vw;
    height: 30rem;
    display: flex;
    // background-color: yellow;
    align-items: center;
    flex-direction: column;
    z-index: 1;
    // bottom: 10rem;
    scroll-margin: 7rem;

    .commodities-wrapper {
        width: 62.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 50rem;
        // background-color:blue;
        .commoditiesTitle {
            // margin-top: 3.75rem;
            font-style: normal;
            font-weight: bold;
            font-size: 2.25rem;
            line-height: 2.625rem;
            text-align: center;
        }
        .commoditiesParagraph {
            text-align: center;
            width: 50rem;
            font-style: normal;
            font-weight: 300;
            line-height: 150%;
            font-size: 1.25rem;
        }
        .CropImages_sec {
            // background-color:green;
            .CropImagesWeb {
                width: 62.5rem;
                height: 9.375rem;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                div {
                    text-align: center;
                    p {
                        margin-top: 1.25rem;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 1.125rem;
                    }
                    img {
                        width: 8rem;
                        height: 8rem;
                    }
                }
            }
        }
        .pageDivider {
            position: relative;
            top: 4rem;
        }
        // Kannada Styling
        .commodities-title-kn {
            font-size: 2rem;
        }

        .commodities-title {
            font-size: 2.5rem;
        }

        // Kannada Styling
        .commodities-paragraph-kn {
            width: 100%;
            font-size: 1rem;
            line-height: 2rem;
        }

        .commodities-paragraph {
            width: 100%;
            font-size: 1rem;
            line-height: 2rem;
        }

        .commodities-list {
            float: left;
            width: 10rem;
            margin-top: 1.5rem;
        }

        .commodities-name {
            font-size: large;
        }

        .commodities-image {
            width: 9rem;
            height: 10rem;
        }

        .wheat-image {
            margin-top: 1rem;
            width: 90%;
        }
    }
}

#users {
    scroll-margin: 6rem;
    position: relative;
    bottom: 10rem;

    // Kannada Styling
    .user-title-kn {
        text-align: center;
        font-size: 2rem;
    }

    .user-title {
        text-align: center;
        font-size: 2.5rem;
    }

    .user-column {
        margin-left: 3rem;

        // Kannada Styling
        .user-card-kn {
            background-color: #e0efea;
            width: fit-content;
            font-size: 1rem;
            height: 26rem;
            text-align: center;
        }

        .user-card {
            background-color: #e0efea;
            width: fit-content;
            font-size: 1rem;
            height: 26rem;
            text-align: center;
        }
    }

    // Kannada Styling
    .card-subtitle-kn {
        font-size: 1rem;
    }

    // Kannada Styling
    .card-text-kn {
        font-size: 0.9rem;
    }

    .card-text {
        font-size: 1rem;
    }

    .user-image {
        width: 7.813rem;
        height: 7.813rem;
    }

    .transport-image {
        margin: 2rem;
        width: 90%;
    }
}

.about-us-video-modal {
    .ant-modal-body {
        height: 90vh;
    }
}

#aboutUs {
    margin: 2.5rem;
    width: 95%;
    scroll-margin: 6rem;
    padding: 1rem 2rem;
    position: relative;
    bottom: 10rem;
    background-color: #fbfbfb;

    // Kannada Styling
    .about-us-title-kn {
        padding-top: 1rem;
        font-size: 2rem;
    }

    .about-us-title {
        padding-top: 1rem;
        font-size: 2.5rem;
    }

    .outline-image {
        position: relative;
        float: right;
        bottom: 5rem;
        height: 10rem;
    }

    // Kannada Styling
    .outline-paragraph-kn {
        font-size: 1rem;
        width: 90%;
    }

    .outline-paragraph {
        font-size: 1.2rem;
        width: 90%;
    }

    .vision-card {
        width: fit-content;
        height: 17rem;
        background-color: #e0efea;
        text-align: center;

        // Kannada Styling
        .card-paragraph-kn {
            width: 15rem;
            font-size: 1rem;
            margin: 0 auto;
        }

        .card-paragraph {
            width: 18rem;
            font-size: large;
            margin: 0 auto;
        }
    }

    .vision-div {
        padding-top: 1rem;

        // Kannada Styling
        .vision-paragraph-kn {
            width: fit-content;
            font-size: 1rem;
        }

        .vision-paragraph {
            width: fit-content;
            font-size: large;
        }
    }
}

#team {
    position: relative;
    z-index: 0;
    top: 100px;
    width: 100vw;
    height: fit-content;
    // background-color:orange;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    scroll-margin: 7rem;

    .teamWrapper {
        width: 75rem;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // background-color:green;
        .teamTitle {
            margin-top: 1.25rem;
            font-style: normal;
            font-weight: 700;
            font-size: 2.25rem;
            text-align: center;
        }

        .Founders_sec {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-evenly;
            width: 62.5rem;
            height: 45rem;
            background: white;
            border: 0.063rem solid rgba(18, 128, 92, 0.19);
            box-sizing: border-box;
            border-radius: 0.625rem;
            .FoundersTitle {
                width: 8rem;
                position: relative;
                font-style: normal;
                font-weight: 700;
                font-size: 1.5rem;
                left: 3.813rem;
                top: -1.3rem;
                align-self: start;
                background-color: white;
                text-align: center;
            }
            .FoundersRow {
                width: 59.375rem;
                height: fit-content;
                margin-top: 10px;
                margin-bottom: 10px;
                background-color: rgba(245, 163, 26, 0.05);
                padding: 1.25rem;
                border-radius: 0.625rem;
                border: 0.063rem solid rgba(245, 163, 26, 0.25);
                img {
                    width: 9.375rem;
                    height: 9.375rem;
                }
                .FounderPara {
                    width: 31.25rem;
                    height: fit-content;
                    margin-top: 0.625rem;
                    // background-color: #12805c;
                    // box-sizing: border-box;
                    line-height: 70%;
                    p {
                        margin: 0rem;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 0.875rem;
                        color: #12805c;
                    }
                    .para {
                        margin-top: 0.625rem;
                        font-style: bold;
                        font-weight: 400;
                        font-size: 1rem;
                        line-height: 130%;
                        color: #000000;
                    }
                }
            }
        }
        .Management_sec {
            margin-top: 5rem;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-between;
            width: 62.5rem;
            height: fit-content;
            background: white;
            border: 0.063rem solid rgba(245, 163, 26, 0.3);
            box-sizing: border-box;
            border-radius: 0.625rem;
            .FoundersTitle {
                width: 15rem;
                position: relative;
                font-style: normal;
                font-weight: 700;
                font-size: 1.5rem;
                left: 3.813rem;
                top: -1.5rem;
                align-self: start;
                background-color: white;
                text-align: center;
            }
            .ManagementRow {
                width: 59.375rem;
                height: fit-content;
                margin-bottom: 1.125rem;
                background-color: rgba(18, 128, 92, 0.05);
                padding: 1.25rem;
                border-radius: 0.625rem;
                border: 0.063rem solid rgba(18, 128, 92, 0.25);
                img {
                    width: 9.375rem;
                    height: 9.375rem;
                    border-radius:50%;
                }
                .FounderPara {
                    width: 31.25rem;
                    height: fit-content;
                    margin-top: 0.625rem;
                    // background-color: #12805c;
                    box-sizing: border-box;
                    line-height: 100%;
                    p {
                        margin: 0rem;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 0.875rem;
                        color: #12805c;
                    }
                    .para {
                        margin-top: 0.625rem;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 1rem;
                        line-height: 130%;
                        color: #000000;
                    }
                }
            }
        }
    }
    .pageDivider {
        position: relative;
        top: 4.125rem;
    }
}

#legal {
    width: 100vw;
    height: fit-content;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 330px;
    bottom: 10rem;
    scroll-margin: 17rem;
    // background-color: $col-shade-green;
    color: black;
    z-index: 1;
    .legalWrapper {
        width: 50rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        // background-color:orange;

        .legal-title {
            position: relative;
            top: -9.375rem;
            color: black;
            font-style: normal;
            font-weight: 700;
            font-size: 2.25rem;
        }
        .legal-tab {
            position: relative;
            bottom: 7rem;

            .ant-tabs-tab,
            .ant-tabs-tab-active {
                width: 25rem;
                display: inline-flex;
                padding: 0.3rem 1rem;
                font-size: 1rem;
                border-radius: 0.625rem 0.625rem 0rem 0rem;
                color: white;
            }

            .ant-tabs-tab-btn {
                width: 100%;
                // background-color:orange;
                color: black;
                text-align: center;
            }
            div.ant-tabs-tab {
                background-color: orange;
            }
            div#rc-tabs-0-tab-1.ant-tabs-tab-btn {
                position: relative;
                left: -13px;
                width: 100vw;
                color: #fff !important;
            }
            div#rc-tabs-0-tab-2.ant-tabs-tab-btn {
                color: #fff !important;
            }

            .ant-tabs-nav {
                position: relative;
                top: 1rem;
            }

            .ant-tabs-tab {
                background-color: rgba(196, 196, 196, 0.15);
                border-bottom: 0.188rem solid orange;
            }

            .ant-tabs-tab-btn-active {
                color: orange !important;
            }

            .ant-tabs-tab-active {
                background-color: $col-shade-green !important;
            }

            .ant-tabs-content-holder {
                width: 100%;
                height: fit-content;
                position: relative;
                border-radius: 0.3rem;
                color: #000000;
                // background-color: #339273;

                ul {
                    li {
                        padding: 2em;
                        padding-bottom: 0;
                        color: black;
                        list-style-type: disc;
                    }
                }

                .read-more-button {
                    background: #f5a31a;
                    color: white;
                    border-radius: 0.313rem;
                    margin-bottom: 2rem;
                }
            }
        }
    }
    .pageDivider {
        position: relative;
        top: -6.25rem;
    }
}

#contactUs {
    position: relative;
    top: 230px;
    height: 40rem;
    // margin-top: 6.25em;
    width: 100vw;
    // background-color:orange;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    scroll-margin: 5rem;
    z-index: 1;
    .contact-us-title {
        position: relative;
        top: 1.563rem;
        font-style: normal;
        font-weight: 700;
        font-size: 2.25rem;
    }
    .contactWrapper {
        position: relative;
        top: 6.25rem;
        // background-color: #12805c;
        width: 62.5rem;

        .contact-us-info {
            font-size: 1.2rem;
            left: 3.125rem;
            .mapouter {
                position: relative;
                top: -3.125rem;
                width: 100%;
                height: 11.125rem;
                border-radius: 0.625rem;
                border: 0.063rem solid #f5a31a;
                overflow-y: hidden;
                .gmap_canvas {
                    overflow: hidden;
                    background: none !important;
                    width: 100%;
                    height: 11.188rem;
                    .gmap_iframe {
                        height: 11.188rem !important;
                    }
                }
            }

            .anticon {
                font-size: x-large;
                color: #f5a31a;
                padding: 1rem 1rem;
                background: rgba(245, 163, 26, 0.05);
                border-radius: 50%;
            }

            .contact-address {
                position: relative;
                bottom: 2.75rem;
                left: 3.35rem;
            }
        }

        .write-feedback {
            position: relative;
            top: 3.125rem;
            background: rgba(245, 163, 26, 0.05);
            padding: 1rem;
            height: 31.25rem;
            margin-bottom: 2rem;
            margin-top: -5.625rem;
            border-radius: 0.313rem;

            .feedback-form {
                text-align: center;
                padding: 0rem 1.875rem 0rem 1.875rem;
                width: 25rem;
                .ant-form-item {
                    margin-bottom: 0rem;
                }
                .ant-btn {
                    color: white;
                    background-color: #f5a31a;
                    height: 2.5rem;
                    margin-top: 0.625rem !important;
                }
            }
        }
    }
    .pageDivider {
        position: relative;
        top: 5.25rem;
    }
}

#updates {
    position: relative;
    top: 330px;
    width: 100vw;
    padding: 1.25rem;
    z-index: 1;
    // background-color: #12805c;
    display: flex;
    align-items: center;
    justify-content: center;
    scroll-margin: 7rem;

    .updateWrapper {
        width: 62.5rem;
        margin-top: 0.625rem;
        margin-bottom: 0.625rem;
        box-shadow: 0.25rem 0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.16);
        .updates-title {
            color: black;
            text-align: center;
            font-style: normal;
            font-weight: 700;
            font-size: 2.25rem;
        }
        .oldUpdates {
            width: 100%;
            height: 46px;
            display: flex;
            align-items: center;
            background-color: #12805c21;
            cursor: pointer;
            svg {
                margin-left: 15px;
            }
            span.ant-typography {
                font-weight: 600;
                margin-left: 20px;
            }
        }
        .card {
            width: 56.25rem;
            height: 25rem;
            padding: 1.25rem;
            // background-color: #12805c;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            img {
                width: 25rem;
                height: 18.75rem;
            }
            p {
                position: relative;
                left: 1.25rem;
                text-align: left;
                font-size: 1rem;
            }
        }
    }
}

// Styling for web page
@media screen and (min-width: 767px) {
    .mobile-home-content {
        display: none;
    }
}

//Styling for mobile or tab
@media screen and (max-width: 767px) {
    .mobile-display-none {
        display: none !important;
    }
    html,
    body {
        width: 100vw !important;
        overflow-x: hidden !important;
        margin: 0rem !important;
        padding: 0rem !important;
        position: fixed !important;
    }

    .fixed-card-join {
        position: fixed;
        top: 82.2vh;
        left: 0vw;
        z-index: 1;

        .join-us {
            width: 100vw;
            height: 12.5vh;
            padding: 0;
            margin: 0rem;
            border-radius: 0.4rem;
            text-align: left;

            .fixed-title {
                font-size: 1rem;
                position: relative;
                left: 5rem;
            }

            .join-image {
                width: 12.5vw;
                height: 12.5vw;
                position: relative;
                bottom: 3rem;
            }

            .join-us-reg {
                width: 25vw;
                height: 5vh;
                font-size: 1rem;
                position: relative;
                bottom: 7rem;
                left: 16rem;
            }
        }
    }

    #home {
        width: 100%;
        height: fit-content;
        position: relative;
        top: 5vh;
        padding: 2.625rem;
        // background:blue;
        align-items: center !important;
        .home-wrapper {
            padding-right: 0rem;
            height: 46.5rem;
            // background-color:orange;
        }

        .ant-row {
            .ant-col-10 {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                .wimage,
                .registerComponent {
                    width: 80%;
                }
                .homeTitle {
                    position: relative;
                    left: -0.625rem;
                    width: 18.75rem;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1.813rem;
                }
                .homeTitleKan {
                    width: 31.25rem;
                    font-weight: 600;
                    font-size: 1.813rem;
                    // background-color: blue;
                    text-align: center;
                }
                h5 {
                    width: 80%;
                }
                .googlePlay {
                    display: flex;
                    justify-content: center;
                    width: 12.5rem;
                    height: 2.769rem;
                    margin-top: 3rem;
                    Button,
                    img {
                        width: 9.375rem;
                        height: 2.769rem;
                        margin-left: 0rem;
                        border: none;
                    }
                }
                .headFooter {
                    position: relative;
                    left: 3.125rem;
                    width: 18.75rem;
                    height: fit-content;
                    // background-color:blue;
                    .secondL,
                    .ant-breadcrumb {
                        width: 18.75rem;
                        height: 13.5rem;

                        .ant-breadcrumb {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: space-evenly;
                        }
                    }
                }
            }
        }
    }

    #aim {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100vw;
        padding: 5rem;
        top: 0rem;
        // background-color: orange;
        height: 187.5rem;
        scroll-margin: 1.25rem;

        .aim-wrapper {
            width: 100vw;
            height: 156.25rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            // background-color:yellow;
            .aimFirstRow {
                height: 40.5rem;
                left: 0rem;
                top: -2rem;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
                // background-color:blue;
                .aim-Whatis {
                    width: 18.75rem;
                    .aim-header {
                        width: 80vw;
                        position: relative;
                        // background: blue;
                        font-size: 1.875rem;
                    }
                    p {
                        width: 75vw;
                        line-height: 150%;
                    }
                }
                .videoContent {
                    position: relative;
                    width: 18.75rem;
                    height: 12.5rem;
                    top: 1.25rem;
                    left: -1.875rem;
                }
                .divider {
                    position: relative;
                    top: -20.125rem;
                    // background-color:blue;
                }
            }

            .why-vikasBandhu {
                position: relative;
                top: -9.375rem;
                font-size: 1.875rem;
            }
            .at-present-text {
                width: 80vw;
                position: relative;
                top: -14.375rem;
                text-align: center;
                font-size: 1.25rem;
            }

            .ExistingMarketPlaces {
                position: relative;
                top: -16.5rem;
                width: 100%;
                height: 30rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                // background-color:yellow;
                .firstBox {
                    width: 60vw;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    height: fit-content;
                    margin-top: 20px;
                    // background-color: #12805c;
                    .firstBoxHeading {
                        font-size: 0.938rem;
                        text-align: center;
                        font-weight: 600;
                        // background-color: blue;
                        width: 10.5rem;
                    }
                }
                .secondBox {
                    height: 25.25rem;
                    display: flex;
                    justify-content: space-between;
                    // background-color:blue;
                    .ant-col-4.existingPicture {
                        position: relative;
                        top: 10%;
                        left: 80%;
                        transform: translate(-50%, -50%);
                        width: 100px;
                        height: 100px;
                        // background-color:orange;
                    }
                    .insidePara {
                        .insideRows {
                            // position: relative;
                            // left:100px;
                            width: 100%;
                            height: 4.25rem;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            // justify-content: space-between;
                            // background-color:yellow;
                            .points {
                                div {
                                    position: relative;
                                    left: -1.25rem;
                                    top: -20px;
                                }
                            }
                            .ant-col-20 {
                                p {
                                    position: relative;
                                    left: 70px;
                                    width: 100%;
                                    height: 3.75rem;
                                    font-size: 0.938rem;
                                    font-weight: 0;
                                    line-height: 120%;
                                    font-style: normal;
                                    // background-color: green;
                                }
                            }
                        }
                    }
                }
            }

            .BuyerSellerRow {
                position: relative;
                top: -18.75rem;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 21.875rem;
                .mdScreen {
                    display: none;
                }

                .mobileBuyerSellerRow {
                    position: relative;
                    height: 21.875rem;
                    // background-color: yellow;
                    .ant-col-24 {
                        .BuyerSellerAdvantage {
                            width: 100vw;
                            div {
                                width: 100vw;
                                height: 21.875rem;
                            }
                            .inside {
                                height: 26.875rem;
                                // background-color:orange;
                                border-radius: 0.313rem;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: space-evenly;
                                .headText {
                                    display: flex;
                                    width: 200px;
                                    height: 200px;
                                    flex-direction: column;
                                    line-height: 170%;
                                    // align-items: center;
                                    // justify-content: space-evenly;
                                    // background-color: #12805C;
                                    // .span-text {
                                    //     position: relative;
                                    //     left: -2.125rem;
                                    // }
                                }
                                .CImage {
                                    position: relative;
                                    left: -6.25rem;
                                    top: -4.375rem;
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: center;
                                    img {
                                        width: 5.5rem;
                                        height: 5.5rem;
                                    }
                                }
                                .headText {
                                    position: relative;
                                    left: 20%;
                                    top: 4.375rem;
                                    width: 12.5rem;
                                    height: 9.375rem;
                                    margin-bottom: 3.125rem;
                                    // background-color: #12805C;
                                    .span-text {
                                        font-size: 1.875rem;
                                    }
                                    p {
                                        position: relative;
                                        font-size: 0.875rem;
                                        margin: 0rem;
                                    }
                                }
                                .pointsSection {
                                    position: relative;
                                    top: -3.125rem;
                                    width: 100vw;
                                    height: 25rem;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: space-between !important;
                                    // background-color:orange;
                                    .sellerBuyerPoints {
                                        width: 80vw;
                                        // background-color: blue;
                                        height: 3.75rem;
                                        line-height: 110%;
                                        .ant-col-4 {
                                            height: 12.5rem;
                                            position: relative;
                                            left: 0.625rem;
                                            // background-color:blue;
                                            div {
                                                width: 2rem;
                                                height: 2rem;
                                                border-radius: 50%;
                                                font-size: large;
                                                text-align: center !important;
                                                text-justify: start;
                                            }
                                        }
                                    }
                                }
                            }
                            .sellerBox {
                                width: 100vw;
                                height: 18.75rem;
                                background: rgba(246, 104, 43, 0.1);
                            }
                            .buyerBox {
                                width: 100vw;
                                height: 18.75rem;
                                background: rgba(245, 163, 26, 0.05);
                            }
                        }
                    }
                }
            }

            .line21 {
                position: relative;
                top: -25rem;
            }

            .addressText {
                position: relative;
                top: -28.125rem;
                font-weight: 600;
                font-size: 1.5rem;
            }

            .adressAboveIsusse {
                padding: 0.625rem;
                margin: 0rem;
                width: 100vw;
                position: relative;
                top: -31.25rem;
                height: 11.875rem;
                // background-color:green;
                .addressBriefText {
                    padding: 0rem;
                    text-align: center;
                    width: 90vw;
                    font-size: 0.938rem;
                    font-weight: 700;
                    // background:blue;
                }
            }
            .pageDivider {
                position: relative;
                top: -34.375rem;
            }
        }
    }

    #ecosystem {
        width: 100vw;
        height: 187.5rem;
        top: -39.625rem;
        scroll-margin: 5.7rem;
        // background-color:blue;

        .ecosystem-wrapper {
            height: 156.25rem;
            align-items: center !important;
            justify-content: space-evenly;
            //   background:yellow;
            .Solution {
                position: relative;
                top: -6.25rem;
                // background-color:blue;
            }
            .technologyDiffereniator {
                position: relative;
                top: -9.375rem;
                width: 100vw;
                height: 43.75rem;
                // background-color: aqua;
                .headinginfirstsec {
                    position: relative;
                    top: 1.875rem;
                    width: 100vw;
                    text-align: center;
                    // background-color: yellow;
                }
                .technologyBoxes {
                    display: none !important;
                }
                .Phone-view {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: flex-start;
                    width: 100vw;
                    height: 37.5rem;
                    // background-color:blue;
                    .ant-col-24 {
                        width: 80vw;
                        height: 31.25rem;
                        // background-color:orange;
                        div {
                            display: flex;
                            justify-content: center;
                            height: 31.25rem;
                            // background:yellow;
                        }
                        .firstBox,
                        .secondBox,
                        .thirdBox,
                        .fourthBox {
                            width: 17.5rem;
                            height: 22.125rem;
                            box-sizing: border-box;
                            border-radius: 1.563rem;
                            position: relative;
                            top: 6.25rem;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-around;
                            align-items: center;
                            // background-color:yellow;
                            .inner-text {
                                position: relative;
                                top: -2.5rem;
                                font-weight: 700;
                                width: 12.664rem;
                                height: 1.313rem;
                                font-size: 1.125rem;
                                text-align: center;
                            }
                            ul {
                                width: 14.563rem;
                                height: 11.5rem;
                                // background-color:aqua;
                                font-weight: 400;
                                font-style: normal;
                                font-size: 1.125rem;
                                line-height: 1.313rem;
                            }
                            .topImage {
                                position: relative;
                                top: 2%;
                                left: 27%;
                                -ms-transform: translate(-50%, -50%);
                                transform: translate(-50%, -50%);
                                width: 9.875rem;
                                height: 9.875rem;
                                background-color: white;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                box-shadow: 0rem 0rem 0.625rem rgba(0, 0, 0, 0.25);
                            }
                        }
                        .firstBox {
                            border: 0.063rem solid #f7682b;
                            background: rgba(246, 104, 43, 0.1);
                        }
                        .secondBox {
                            border: 0.063rem solid #f5a31a;
                            background: rgba(245, 163, 26, 0.1);
                        }
                        .thirdBox {
                            border: 0.063rem solid #12805c;
                            background: rgba(18, 128, 92, 0.1);
                        }
                        .fourthBox {
                            border: 0.063rem solid #4285f4;
                            background: rgba(66, 133, 244, 0.1);
                        }
                    }
                }
                .OnhoverDisplay {
                    display: none;
                }
            }
            .howVikasbandhuworks {
                .firstHead {
                    position: relative;
                    top: -18.625rem;
                    width: 100vw !important;
                    height: 3.125rem;
                    font-size: 1.875rem;
                    text-align: center !important;
                    // background-color:yellow;
                    color: black;
                }
                .container-Phone {
                    display: none !important;
                }
                .iphone-screen-mobile {
                    position: relative;
                    top: -18.625rem;
                    width: 80vw;
                    // background-color:green;
                    height: 43.75rem;
                    div.ant-col.ant-col-24 {
                        div {
                            position: relative;
                            left: -0.313rem;
                            top: -0.625rem;
                        }
                        div p {
                            position: relative;
                            top: 3.75rem;
                            left: 5.625rem;
                            width: 12.5rem;
                            height: 6.25rem;
                            //   background-color:rgb(191, 199, 191);
                        }
                    }
                    img {
                        top: 6.25rem;
                        left: 4rem;
                    }
                }
            }

            .keyFeatures {
                width: 100vw;
                position: relative;
                top: -19.625rem;
                text-align: center;
                font-size: 1.25rem;
            }
            img {
                position: relative;
                top: 0rem;
            }

            .lastContent-ecosystem {
                position: relative;
                top: -23.625rem;
                .lastContentWeb {
                    display: none !important;
                }
                .ant-col-24 {
                    width: 100vw;
                    // background-color: #12805C;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    div {
                        width: 80vw;
                        height: 15.625rem;
                        // background-color:blue;
                    }
                    .lastContentBadges {
                        // position: relative;
                        position: relative;
                        top: 50%;
                        left: 30%;
                        transform: translate(-50%, -50%);
                        // top:3.125rem;
                        width: 6.25rem;
                        height: 6.25rem;
                        border-radius: 50%;
                        background-color: rgba(245, 163, 26, 0.05);
                        display: flex;
                        flex-direction: column;
                        margin: 0rem 3.125rem 0rem 3.125rem;
                        align-items: center;
                        text-align: center;
                        div {
                            position: relative;
                            top: -7.188rem;
                            width: 2.5rem;
                            height: 2.5rem;
                            margin: 0.125rem;
                            z-index: 1;
                        }
                        .insideText {
                            width: 12.663rem;
                            height: 1.313rem;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 1.125rem;
                            margin: 0rem;
                        }
                        .outerText {
                            width: 12.663rem;
                            height: 2.5rem;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 0.875rem;
                        }
                    }
                }
            }

            .pageDivider {
                position: relative;
                top: -14.125rem;
            }
            .smallDivider {
                display: none;
            }
            #BestPracticesContainer,
            .BestPracticesContainer {
                position: relative;
                top: -19.375rem;
                width: 100vw;
                // background-color:blue;
                height: 25rem !important;
                justify-content: space-evenly;
                .BestPracticesText {
                    width: 100vw;
                    text-align: center;
                    // background-color:blue;
                    position: relative;
                }
                .theInsideBest {
                    padding: 1.875rem !important;
                    width: 100vw;
                }
            }

            .inAdditionContent {
                display: flex;
                justify-content: center;
                .inAdditionWeb {
                    display: none;
                }
                .inAdditionPhone {
                    div {
                        position: relative;
                        width: 100vw;
                        height: 12.5rem;
                    }
                    .ant-col-24 {
                        width: 100vw;
                        text-align: center;
                        .insideContainer {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            text-align: center;
                            // justify-content: space-between;
                            width: 12.664rem;
                            height: 10rem;
                            background-position: center;
                            background-image: URL('../static/assets/blob (1).png');
                            background-repeat: no-repeat;
                            div {
                                position: relative;
                                top: 1.875rem;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                align-items: center;
                                width: 15.063rem;
                                // background-color:blue;
                                height: 5.625rem;
                                .insideText {
                                    width: 20.063rem;
                                    height: 1.313rem;
                                    font-style: normal;
                                    font-weight: 700;
                                    font-size: 1.125rem;
                                }
                                p {
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 0.875rem;
                                    width: 12.664rem;
                                    height: 2rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    #commodities {
        top: -55.625rem;
        height: 50rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        scroll-margin: 6.25rem;
        // background-color:green;
        .commodities-wrapper {
            height: 43.75rem;
            // background-color:blue;
            .commoditiesTitle {
                font-size: 1.875rem;
            }
            .commoditiesParagraph {
                width: 100vw;
                padding: 0.625rem;
            }
            .CropImages_sec {
                .CropImagesWeb {
                    display: none;
                }
                .ant-col-24 {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100vw;
                    height: 18.75rem;
                    // background-color: yellow;
                    div {
                        width: 70vw;
                        height: 15.625rem;
                        // background-color:blue;
                    }
                    div {
                        text-align: center;
                        // background-color:red;
                        p {
                            margin-top: 1.25rem;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 1.125rem;
                        }
                        img {
                            position: relative;
                            top: 30%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 8rem;
                            height: 8rem;
                        }
                    }
                }
            }
            .ant-divider-plain.ant-divider-with-text {
                position: relative;
                top: -3.125rem;
            }
        }
    }

    #team {
        top: -59.125rem;
        scroll-margin: 10.5vh;

        .teamWrapper {
            width: 100vw;
            //background-color:green;
            display: flex;
            align-items: center;
            justify-content: center;
            .Founders_sec {
                width: 18.75rem;
                height: fit-content;
                display: flex;
                justify-content: center;
                text-align: center;
                .FoundersTitle {
                    top: -1.25rem;
                    left: 5.5rem;
                }
                .FoundersRow {
                    width: 15.625rem;
                    height: fit-content;
                    margin-bottom: 0.625rem;
                    .ant-col-6 img {
                        position: relative;
                        width: 8rem;
                        height: 8rem;
                        border-radius: 50%;
                        left: 80%;
                        top: -10px;
                    }
                    .FounderPara {
                        width: 18.75rem;
                        height: 400px;
                        position: relative;
                        // background-color: green;
                        top: -1.125rem;
                        p {
                            line-height: 120%;
                        }
                    }
                }
            }
            .Management_sec {
                width: 18.75rem;
                height: fit-content;
                display: flex;
                justify-content: center;
                text-align: center;
                .FoundersTitle {
                    position: relative;
                    top: -1rem;
                    left: 1.9rem;
                }
                .ManagementRow {
                    width: 15.625rem;
                    height: fit-content;
                    margin-bottom: 0.625rem;
                    .ant-col-6 img {
                        position: relative;
                        top: -0.625rem;
                    }
                }
            }
        }
    }

    #legal {
        top: -50.75rem;
        width: 100vw;
        padding: 1.25rem;
        // background-color:orange !important;
        height: 98.25rem;
        scroll-margin: 165px;
        .legalWrapper {
            width: 100vw !important;
            padding: 1.25rem !important;
            // background-color:green ;
            .legal-title {
                position: relative;
                top: -6.25rem;
            }
            .legal-tab {
                width: 100vw !important;
                div.ant-tabs-tab {
                    background-color: orange;
                }
                div#rc-tabs-0-tab-1.ant-tabs-tab-btn {
                    position: relative;
                    left: -13px;
                    width: 100vw;
                    color: #fff !important;
                }
                div#rc-tabs-0-tab-2.ant-tabs-tab-btn {
                    color: #fff !important;
                }
                .ant-tabs-tab {
                    width: 50vw;
                }
            }
        }
        .pageDivider {
            position: relative;
            top: -9.375rem;
        }
    }

    #contactUs {
        //   background-color: #F5761A;
        top: -53rem;
        width: 100vw;
        scroll-margin: 32vh;
        .contact-us-title {
            position: relative;
            top: 6.25rem;
        }
        .contactWrapper {
            width: 100vw;
            display: flex;
            justify-content: center;
            .ant-row {
                display: flex;
                justify-content: start;
                padding: 0;
                width: 100vw;
                //   background-color:green;
                .ant-col-12 {
                    position: relative;
                    left: -0.625rem;
                    padding-left: 1.25rem;
                    margin-bottom: 0.625rem;
                    font-size: 0.625rem;
                    .contact-details {
                        height: 8.125rem;
                        width: 80vw;
                        font-size: 0.938rem;
                        text-align: start;
                    }
                    .mapouter {
                        position: relative;
                        top: -5rem;
                    }
                }
                .write-feedback {
                    padding: 0.625rem;
                    display: flex;
                    justify-content: center;
                    .feedback-form {
                        height: 37.5rem;
                        //   background-color:#f5a31a;
                        #miform {
                            .ant-form-item {
                                width: 18.75rem;
                            }
                        }
                    }
                }
            }
        }
        .pageDivider {
            position: relative;
            top: 6.25rem;
        }
    }

    #updates {
        top: -32.75rem;
        .updateWrapper {
            width: 100vw;
            .site-collapse-custom-collapse {
                .site-collapse-custom-panel {
                    .card {
                        width: 90vw;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        height: fit-content;
                        img {
                            position: relative;
                            top: 0.313rem;
                            width: 12.5rem;
                            height: 12.5rem;
                        }
                        p {
                            position: relative;
                            top: 0.313rem;
                            width: 18.75rem;
                        }
                    }
                }
            }
        }
    }
}
