.otp-modal {
    .custom-otp-modal {
        .ant-modal-content {
            width: 24em;
    
            .ant-modal-header {
                padding-bottom: 0;
                border: none;
            }
    
            .ant-modal-body {
                padding-top: 0;
    
                .custom-color-change {
                    color: #cacaca !important;
                    
                    .ant-statistic-content-value {
                        color: #cacaca !important;
                        font-size: initial;
                        padding-bottom: 0.5em;
                    }
                }

                .add-margin-bottom {
                    margin-bottom: 0.5em;
                }

                .confirm-otp-modal-warning {
                    background-color: white;
                    border: none;
                    line-height: 1em;
                }

                .confirm-otp-divider {
                    margin: 0;
                }
            }
        }
    }
}