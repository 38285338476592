.app-footer-container {
    width: 100%;
    background: #102E44;
    height: 6.25rem;
    top:25rem;
    z-index:1;
    position: relative;
    color: #ffffff;
    padding: 0 1em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;

    .phone {
        padding-right: 5rem;
    }

    .applied-for {
        margin-top: 1.875rem;
    }

    .powered-by-text {
        padding: 0 1em;
        margin: unset;
    }
}

//responsive
@media screen and (max-width: 767px) {
    .app-footer-container {
        position: relative;
        width: 100vw;
        background: #102E44;
        flex-direction: column;
        align-items: center;
        font-size: 1rem;
        height:16.875rem;
        top:-30.625rem ;

        .height-full {
            display:flex;
            align-items: center;
            flex-direction: column;
            width: 100vw;
            margin: 0 -0.875rem;
            height: fit-content;
            padding-top: 1rem;
            .phone {
                padding-right: 0rem;
            }
        }
.applied-for{
    height:12.5rem;
}
.footer-logo{
    width:100%;
    background: #102E44;
        .image {
            width: 55vw;
            height: 20vw;
        }}
    }
}
